import React, {Fragment} from 'react'
import { Box, Typography, Chip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ContactForm from './../ContactForm/ContactForm';

export default function Contact() {
  return (
    <Fragment>
      <Grid container spacing={3}>
                <Grid size={{ xs: 12, md: 12, lg: 7 }}>
                  {/* <Box className="homeContactInfo"> */}
                  <Chip
                    label="GET IN TOUCH"
                    className="homeChip"
                    sx={{ borderRadius: "5px" }}
                  />
                  {/* <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    sx={{ color: "#b19478" }}
                    align="left"
                  >
                    GET IN TOUCH
                  </Typography> */}
                  <Typography
                    variant="h4"
                    align="left"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    color="black"
                  >
                    Reach Our Team
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    // fontWeight={"bold"}
                    fontFamily={"open sans"}
                    my={1}
                    color="black"
                  >
                    Send a message through given form, If your enquiry is time
                    sensitive please use below contact details.
                  </Typography>
                  <Box className="homeContactTimingContainer">
                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, md: 12, lg: 3 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          fontWeight={"bold"}
                          fontFamily={"open sans"}
                          // mt={1}
                          color="#16939c"
                        >
                          Hours:
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 12, md: 12, lg: 9 }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Box mr={5}>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              Monday - Friday
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              Saturday - Sunday
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              9AM - 5PM (EST)
                            </Typography>

                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              Closed
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className="homeContactTimingContainer">
                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, md: 12, lg: 3 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          fontWeight={"bold"}
                          fontFamily={"open sans"}
                          // mt={1}
                          color="#16939c"
                        >
                          Get In Touch:
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 12, md: 12, lg: 9 }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Box mr={7}>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              {" "}
                              (343)-254-4596
                            </Typography>
                            {/* <Typography variant="subtitle2" fontFamily={'open sans'}>Saturday-Sunday</Typography> */}
                          </Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              sales@clovvista.com
                            </Typography>
                            {/* <Typography variant="subtitle2" fontFamily={'open sans'}>Closed</Typography> */}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* </Box> */}
                </Grid>

                <Grid size={{ xs: 12, md: 12, lg: 5 }}>
                  {/* <img src={Image} class="img-fluid" alt="logo"/> */}
                  <Typography
                    variant="h6"
                    align="left"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    color="black"
                  >
                    Drop Us A Line!
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    // fontWeight={"bold"}
                    fontFamily={"open sans"}
                    my={1}
                    color="gray"
                  >
                    Feel free to send us a message about anything you might need
                    help with. We would love to hear from you
                  </Typography>
                  <ContactForm />
                </Grid>
              </Grid>
    </Fragment>
  )
}
