import React, { Fragment } from "react";
import "./Clients.css";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import Client1 from "../../Assets/the-pinnacle-group.png";
// import Client2 from "../../Assets/images-removebg-preview.png";
import Client3 from "../../Assets/DCT.png";
import Client4 from "../../Assets/source.png";
import Client5 from "../../Assets/upwork.png";
import { Fade } from "react-awesome-reveal";


export default function Clients() {

  const clientList = [
    { id: 1, name: "The Pinnacle Group", image: Client1 },
    { id: 2, name: "DCT", image: Client3 },
    { id: 3, name: "Source", image: Client4 },
    { id: 4, name: "Upwork", image: Client5 },
  ]

  return (
    <Fragment>
      <Box className="clientsMainContainer">
        <Box className="m-scroll">
            <Fade triggerOnce='true'>
          <Grid container rowSpacing={5} columnSpacing={0}>

            {clientList.map((index, key) => {
              return (
                <Grid
              size={{ xs: 6, md: 2 }}
              sx={{ display: "flex", alignItems: "center", mr:'0'}}
            >
              <img
                src={index.image}
                class="img-fluid border border-0"
                height={"300"}
                width={"300"}
                alt="c1"
                loading="lazy"
              />
            </Grid>
              )
            })}
            
            {/* <Grid
              size={{ xs: 6, md: 2 }}
              sx={{ display: "flex", alignItems: "center", mr:'0' }}
            >
              <img
                src={Client1}
                class="img-fluid border border-0"
                height={"300"}
                width={"300"}
                alt="c1"
                loading="lazy"
              />
            </Grid> */}
            {/* <Grid
              size={{ xs: 6, md: 2 }}
              sx={{ display: "flex", alignItems: "center", mx:'0' }}
            >
              <img
                src={Client2}
                class="img-fluid border border-0"
                height={"300"}
                width={"300"}
                alt="c2"
              />
            </Grid> */}
            {/* <Grid
              size={{ xs: 6, md: 2 }}
              sx={{ display: "flex", alignItems: "center", mx:'0' }}
            >
              <img
                src={Client3}
                class="img-fluid border border-0"
                height={"300"}
                width={"300"}
                alt="c3"
                loading="lazy"
              />
            </Grid>
            <Grid
              size={{ xs: 6, md: 2 }}
              sx={{ display: "flex", alignItems: "center", mx:'0' }}
            >
              <img
                src={Client4}
                class="img-fluid border border-0"
                height={"300"}
                width={"300"}
                alt="c4"
                loading="lazy"
              />
            </Grid>
            <Grid
              size={{ xs: 6, md: 2 }}
              sx={{ display: "flex", alignItems: "center", mx:'0' }}
            >
              <img
                src={Client5}
                class="img-fluid border border-0"
                height={"300"}
                width={"300"}
                alt="c5"
                loading="lazy"
              />
            </Grid> */}
          </Grid>
            </Fade>
          {/* <span >
                  <img
                    src={Client1}
                    class="img-fluid border border-0"
                    height={"300"}
                    width={"300"}
                    alt="c1"
                  />
                </span>
                <span >
                  <img
                    src={Client2}
                    class="img-fluid border border-0"
                    height={"300"}
                    width={"300"}
                    alt="c2"
                  />
                </span>
                <span >
                  <img
                    src={Client3}
                    class="img-fluid border border-0"
                    height={"300"}
                    width={"300"}
                    alt="c3"
                  />
                </span>
                <span >
                  <img
                    src={Client4}
                    class="img-fluid border border-0"
                    height={"200"}
                    width={"200"}
                    alt="c4"
                  />
                </span>
                <span >
                  <img
                    src={Client5}
                    class="img-fluid border border-0"
                    height={"200"}
                    width={"200"}
                    alt="c5"
                  />
                </span> */}
        </Box>
      </Box>
      {/* <Grid size={{ xs: 6, md: 3 }} sx={{display:'flex', alignItems:'center'}}>
                  <img
                    src={Client1}
                    class="img-fluid border border-0"
                    height={"200"}
                    width={"200"}
                    alt="c1"
                  />
                </Grid>
                <Grid size={{ xs: 6, md: 3 }} sx={{display:'flex', alignItems:'center'}}>
                  <img
                    src={Client2}
                    class="img-fluid border border-0"
                    height={"200"}
                    width={"200"}
                    alt="c2"
                  />
                </Grid>
                <Grid size={{ xs: 6, md: 3 }} sx={{display:'flex', alignItems:'center'}}>
                  <img
                    src={Client3}
                    class="img-fluid border border-0"
                    height={"200"}
                    width={"200"}
                    alt="c3"
                  />
                </Grid>
                <Grid size={{ xs: 6, md: 3 }} sx={{display:'flex', alignItems:'center'}}>
                  <img
                    src={Client4}
                    class="img-fluid border border-0"
                    height={"200"}
                    width={"200"}
                    alt="c4"
                  />
                </Grid>
                <Grid size={{ xs: 6, md: 3 }} sx={{display:'flex', alignItems:'center'}}>
                  <img
                    src={Client5}
                    class="img-fluid border border-0"
                    height={"200"}
                    width={"200"}
                    alt="c5"
                  />
                </Grid> */}
    </Fragment>
  );
}
