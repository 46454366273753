import React, { Fragment, useEffect } from "react";
import "../Service3/ServiceStyles.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import ContactForm from "../../Components/ContactForm/ContactForm";
import Grid from "@mui/material/Grid2";
import { Box, Typography, Chip } from "@mui/material";
import {
  List,
  ListItem,
  ListItemDecorator,
  ListItemContent,
  ListItemButton,
  ListDivider,
} from "@mui/joy";
import HeroImg from "../../Assets/HeroImg.png";
import Img1 from '../../Assets/ui-ux service.png'
import Img2 from "../../Assets/Rectangle 60.png";
import Img3 from "../../Assets/Group 9.png";
import Img4 from "../../Assets/Group 10.png";
import Img5 from "../../Assets/Group 11.png";
import Img6 from "../../Assets/Group 12.png";
import Img8 from "../../Assets/Group 14.png";
import Img9 from "../../Assets/Group 15.png";
import Img10 from "../../Assets/Group 16.png";
import Img11 from "../../Assets/Group 17.png";
import Icon1 from "../../Assets/coding.png";
import Icon2 from "../../Assets/web-design.png"
import Icon3 from "../../Assets/network-security.png"
import Icon4 from "../../Assets/server.png"
import Contact from '../../Components/Contact/Contact'
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

const reasons = [
  {
    id: 1,
    label: "User-Centric Approach",
    description:
      "We conduct thorough user research and usability tests to create designs that resonate with your audience.",
    icon: Img3,
  },
  {
    id: 2,
    label: "Creative Visuals",
    description:
      "Our designs are crafted to reflect your brand identity, combining beauty with functionality.",
    icon: Img4,
  },
  {
    id: 3,
    label: "Interaction Design",
    description:
      "We focus on smooth, interactive experiences to engage users across multiple platforms (web, mobile, and tablets).",
    icon: Img5,
  },
  {
    id: 4,
    label: "Consistency",
    description:
      "We ensure design consistency across your entire digital ecosystem, including websites and apps.",
    icon: Img6,
  },
];

export default function Services() {

  const navigate = useNavigate()
 
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <Fragment>
      <Navbar />

      <Grid container>
        
      <Grid //HERO SECTION
          size={{ xs: 12, md: 12 }}
          id="web-hero-section"
          sx={{ backgroundColor: "hsla(0, 0%, 0%, 0.521)" }}
        >
          <Box className="webServiceHeroContainer">
            <Box className="webServiceOverlay">
              <Box className="webServiceHeroImgContainer">
                <img
                    src={HeroImg}
                    height={300} width={'100%'}
                    alt="backgroundImage" 
                    />
              </Box>
              <Box className="webServiceHeroContentContainer">
                <Box className="webServiceHeroContentInnerContainer">
                  <Fade cascade triggerOnce="true">
                    <Typography
                      variant="h3"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      mt={1}
                      color="white"
                      // sx={{ fontSize: { lg: 66, md: 50, xs: 15 } }}
                    >
                      UI/UX Designing
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontFamily={"open sans"}
                      mt={2}
                      mx={1}
                      color="white"
                      // sx={{ fontSize: { sm: 15, xs: 10 } }}
                    >
                      Crafting Seamless Experiences with Design that Connects
                    </Typography>
                  </Fade>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Box
          sx={{
            // backgroundColor: "red",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "85%", maxWidth: "1536px" }}>
            <Grid container spacing={2}>
              <Grid
                size={{ xs: 12, lg: 3 }}
                sx={{
                  width: "100%",
                  // padding: "4% 2%",
                  // height: "50rem",
                  // backgroundColor: "#f7f8f9",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#f7f8f9",
                    width: "100%",
                    padding: "4% 2%",
                    mt: "2rem",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    mx={0}
                    sx={{ marginBottom: "0px" }}
                  >
                    Services
                  </Typography>
                  <List
                    inset="gutter"
                    size="sm"
                    sx={{
                      "--ListItem-paddingY": "10px",
                    }}
                  >
                    <ListItem>
                      <ListItemButton color="neutral" onClick={() => { navigate('/web-development') }}>
                        <ListItemDecorator sx={{ margin: "0px" }}>
                        <img src={Icon1} height={30} width={30} alt="icon1" />
                        </ListItemDecorator>
                        <ListItemContent fon>Web Development</ListItemContent>
                      </ListItemButton>
                    </ListItem>
                    <ListDivider />
                    <ListItem>
                      <ListItemButton color="neutral" onClick={() => { navigate('/uiux-designing') }}>
                        <ListItemDecorator sx={{ margin: "0px" }}>
                        <img src={Icon2} height={30} width={30} alt="icon1" />
                        </ListItemDecorator>
                        <ListItemContent>UI/UX Designing</ListItemContent>
                      </ListItemButton>
                    </ListItem>
                    <ListDivider />
                    <ListItem>
                      <ListItemButton color="neutral" onClick={() => { navigate('/cloud-solution') }}>
                        <ListItemDecorator sx={{ margin: "0px" }}>
                        <img src={Icon4} height={30} width={30} alt="icon1" />
                        </ListItemDecorator>
                        <ListItemContent>
                          Cloud and Solutions Architecture
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                    <ListDivider />
                    <ListItem>
                      <ListItemButton color="neutral" onClick={() => { navigate('/network-solution') }}>
                        <ListItemDecorator sx={{ margin: "0px" }}>
                        <img src={Icon3} height={30} width={30} alt="icon1" />
                        </ListItemDecorator>
                        <ListItemContent>
                          IT & Networking Solutions
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, lg: 9 }}>
                <Grid container>

                  <Grid //INTRO SECTION
                  size={{ xs: 12, md: 12 }}
                >
                  <Box className="webServiceMainContainer">
                    <Box className="webServiceSecondaryContainer">
                      <Grid container>
                        <Grid size={{ xs: 12, md: 12 }}>
                          <Box className="webServiceIntroImageContainer">
                            <img
                              src={Img1}
                              loading="lazy"
                              alt="design"
                              className="webServiceIntroImage"
                            />
                          </Box>
                        </Grid>
                        <Grid size={{ xs: 12, md: 12 }}>
                          <Box className="webServiceIntroTextContainer">
                          <Chip
                              label="INSPIRING INTERACTION, ELEVATING EXPERIENCES"
                              className="homeChip"
                              sx={{ borderRadius: "5px", padding: "4px 0px 4px 0px", height: 'auto',
                                '& .MuiChip-label': {
                                  display: 'block',
                                  whiteSpace: 'normal',
                                }, }}
                            />
                            <Typography
                              variant="h5"
                              fontWeight={"bold"}
                              fontFamily={"open sans"}
                              mx={0}
                              align="left"
                              sx={{ marginBottom: "10px" }}
                            >
                              Crafting Engaging User Experiences That Inspire and Delight
                            </Typography>
                            <Typography
                              variant="body2"
                              fontFamily={"open sans"}
                              color="#252525"
                              align="left"
                            >
                              Great design is more than just aesthetics—it's about creating a seamless, enjoyable experience for your users. Our UI/UX design services focus on human-centered design principles to create engaging and intuitive digital experiences.
                            </Typography>
                            <Typography
                              variant="body2"
                              fontFamily={"open sans"}
                              color="#252525"
                              align="left" mt={2}
                            >
                              From wireframes to high-fidelity prototypes, we guide you through every step of the design process to ensure that your users' needs are met and exceeded.
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>

                <Grid //WHY CHOOSE US SECTION
                  size={{ xs: 12, md: 12 }}
                >
                  <Box className="webServiceMainContainer" sx={{backgroundColor:'#e7f5ef'}}>
                    <Box className="webServiceSecondaryContainer">
                      <Grid container>
                      <Grid size={{ xs: 12, md: 12 }}>
                      <Chip
                  label="WHY CHOOSE US"
                  className="homeChip"
                  sx={{ borderRadius: "5px", padding: "0px" }}
                />
                          
                            <Typography
                              variant="h5"
                              fontWeight={"bold"}
                              fontFamily={"open sans"}
                              mx={0}
                              sx={{ marginBottom: "0px" }}
                            >
                              Save Time and Effort With Us
                            </Typography>
                        </Grid>
                        <Grid size={{ xs: 12, md: 7 }}>
                          <Box className="webServiceChooseContainer">
                          
                            <Box className="webServiceLeftContainer">
                              {reasons.map((index) => {
                                return (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                      marginTop: "1rem",
                                    }}
                                  >
                                    <img
                                      src={index.icon}
                                      height={30}
                                      width={30}
                                      alt='point icons'
                                      loading="lazy"
                                    />
                                    <Typography
                                      variant="h6"
                                      mx={3}
                                      fontWeight={"bold"}
                                    >
                                      {index.label}
                                      <Typography variant="subtitle2">
                                        {index.description}
                                      </Typography>
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid size={{ xs: 12, md: 5 }}>
                          <Box className="webServiceRightContainer">
                            <img
                              src={Img2}
                              loading="lazy"
                              class="img-fluid"
                              alt="backgroundImage"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid> 

                <Grid //PROCESS SECTION
                  size={{ xs: 12, md: 12 }}
                >
                  <Box
                    className="webServiceMainContainer"
                    // sx={{ backgroundColor: "#e7f5ef" }}
                  >
                    <Box className="webServiceSecondaryContainer">
                      <Grid container>
                        <Grid size={{ xs: 12, md: 12 }}>
                          <Box className="webServiceProcessTextContainer">
                          <Chip
                  label="WHAT WE DO"
                  className="homeChip"
                  sx={{ borderRadius: "5px", padding: "0px" }}
                />
                            <Typography
                              // align="center"
                              variant="h5"
                              fontWeight={"bold"}
                              fontFamily={"open sans"}
                              mx={0}
                              sx={{ marginBottom: "0px" }}
                            >
                              Check Out Our Work Process
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid size={{ xs: 12, md: 12 }}>
                          <Box className="webServiceStepperContainer">
                            <Grid container spacing={4}>
                              <Grid size={{ xs: 12, md: 3 }}>
                                <Box className="webServiceStepContainer">
                                  <Box
                                    className="webServiceStepCircle1"
                                    sx={{ height: 200, width: 200 }}
                                  >
                                    <img
                                      src={Img8}
                                      loading="lazy"
                                      class="img-fluid"
                                      alt="Step1"
                                    />
                                  </Box>
                                </Box>
                                <Typography
                                  variant="body1"  fontFamily={"open sans"}
                                  fontWeight={"900"}
                                  mt={1}
                                >
                                  Discovery & Research
                                </Typography>
                                <Typography variant="caption"  fontFamily={"open sans"} color="#252525" mt={1}>
                                We gather insights from user research, competitor analysis, and market trends to understand user pain points.
                                </Typography>
                              </Grid>
                              <Grid size={{ xs: 12, md: 3 }}>
                                <Box className="webServiceStepContainer">
                                  <Box
                                    className="webServiceStepCircle1"
                                    sx={{ height: 200, width: 200 }}
                                  >
                                    <img
                                      src={Img9}
                                      loading="lazy"
                                      class="img-fluid"
                                      alt="Step2"
                                    />
                                  </Box>
                                </Box>
                                <Typography  fontFamily={"open sans"}
                                  variant="body1"
                                  fontWeight={"900"}
                                  mt={1}
                                >
                                  Wireframing & Prototyping
                                </Typography>
                                <Typography variant="caption"  fontFamily={"open sans"} color="#252525" mt={1}>
                                Initial sketches and wireframes are created to outline the basic structure. Prototypes follow to define user interactions.
                                </Typography>
                              </Grid>
                              <Grid size={{ xs: 12, md: 3 }}>
                                <Box className="webServiceStepContainer">
                                  <Box
                                    className="webServiceStepCircle1"
                                    sx={{ height: 200, width: 200 }}
                                  >
                                    <img
                                      src={Img10}
                                      loading="lazy"
                                      class="img-fluid"
                                      alt="Step3"
                                    />
                                  </Box>
                                </Box>
                                <Typography
                                  variant="body1"  fontFamily={"open sans"}
                                  fontWeight={"900"}
                                  mt={1}
                                >
                                  Design & Development
                                </Typography>
                                <Typography variant="caption"  fontFamily={"open sans"} color="#252525" mt={1}>
                                High-fidelity designs are created based on feedback and client needs.
                                </Typography>
                              </Grid>
                              <Grid size={{ xs: 12, md: 3 }}>
                                <Box className="webServiceStepContainer">
                                  <Box
                                    className="webServiceStepCircle1"
                                    sx={{ height: 200, width: 200 }}
                                  >
                                    <img
                                      src={Img11}
                                      loading="lazy"
                                      class="img-fluid"
                                      alt="Step4"
                                    />
                                  </Box>
                                </Box>
                                <Typography
                                  variant="body1"  fontFamily={"open sans"}
                                  fontWeight={"900"}
                                  mt={1}
                                >
                                  User Testing
                                </Typography>
                                <Typography variant="caption"  fontFamily={"open sans"} color="#252525" mt={1}>
                                  {" "}
                                  Testing the designs with real users to gather feedback and refine the final product.
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                  {/* </Box> */}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Grid //CONTACT SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          id="contact"
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Box className="OuterContainer">
            <Box className="InnerContainer">
              <Contact/>
            </Box>
          </Box>
        </Grid>

        <Grid //FOOTER SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Footer />
        </Grid>
      </Grid>
    </Fragment>
  );
}
