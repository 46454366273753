import React from "react";
import "./Navbar.css";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  IconButton,
  Container,
  Button,
  useScrollTrigger,
  Toolbar,
  Drawer,
  Menu, MenuItem, Fade, Typography
} from "@mui/material";
import Divider from '@mui/joy/Divider'
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../Assets/Logo (2).png";
import Logo1 from '../../Assets/Logo.png'
import LogoColored from '../../Assets/logoColored.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import homeIcon from '../../Assets/home.png'
import contactIcon from '../../Assets/phone-call.png'
import serviceIcon from '../../Assets/customer-care.png'
import uspIcon from '../../Assets/gem.png'
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
// import { grey } from '@mui/material/colors';


// import { GoTools } from "react-icons/go";


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(3),
    minWidth: 180,
    width: 300,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      fontSize:'small',
      color:'#002129',
      margin:'0px',
      // position: 'relative',
      // BorderBottom:'1px solid #002129',
      transition: 'right ease 0.5s',
      '& .MuiSvgIcon-root': {
        display:'none',
      },
      '&:hover .MuiSvgIcon-root': {
       fontSize: 10,
        color: '#002129',
        marginRight: theme.spacing(1.5),
        display:'flex',
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));

function ResponsiveAppBar() {

  const navigate = useNavigate()

  // const btnColor = grey[50]; // #fafafa

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;
  const trigger = useScrollTrigger({ disableHysteresis: true });

  // const HomeButton = () => {
    
  //   navigate('/#services')
    
  //   const element = document.getElementById("services");
  //   console.log(element);
  //   if (element) {
  //     // 👇 Will scroll smoothly to the top of the next section
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  // const USPButton = () => {
  //   const element = document.getElementById("usp");
  //   console.log(element);
  //   if (element) {
  //     // 👇 Will scroll smoothly to the top of the next section
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  // const ServicesButton = () => {
  //   const element = document.getElementById("services");
  //   console.log(element);
  //   if (element) {
  //     // 👇 Will scroll smoothly to the top of the next section
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  const ContactButton = () => {
    const element = document.getElementById("contact");
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  // const PricingButton = () => {
  //   const element = document.getElementById("pricing");
  //   console.log(element);
  //   if (element) {
  //     // 👇 Will scroll smoothly to the top of the next section
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  // Dropdown Menu For the Services
 
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  // Small Screen Drawer Opening Function
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 220 }} role="presentation" onClick={toggleDrawer(false)}>
     <Box sx={{ width:'100%', display:'flex', flexDirection:'row', mb:'20px'  }}>
        <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(false)}
              color="inherit"
            >
              <MenuIcon />
        </IconButton>
        <Box
            sx={{
              width:'100%',
              display: { xs: "flex", md: "none" },
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <img src={LogoColored} height={"35"} width={"100"} alt="logo Colored" onClick={ () => { navigate('/')}}/>
        </Box>
      </Box> 
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', padding:'1% 0% 1% 2%'}}>
      
      <HashLink to='/#homepage' sx={{padding: '0%'}}> 
      <Button
        className="Button"
        sx={{ color: "#000" }}
        size="small"
        startIcon={<img
          src={homeIcon}
          height="15"
          width="15"
          alt="Home Icon"
          loading="lazy"
        />}
      >
        Home
      </Button>
      </HashLink>
      <Divider sx={{backgroundColor:'#002129', margin:'2%'}}/>
      
      <HashLink to='/#usp'  sx={{padding: '0%'}}>
              <Button
              variant="text"
                className="Button"
                size="small"                
               sx={{color:'#000'}}
               startIcon={<img
                src={uspIcon}
                height="15"
                width="15"
                alt="USP Icon"
                loading="lazy"
              />}
              >
                Who We Are
              </Button>
      </HashLink>
      <Divider sx={{backgroundColor:'#002129', margin:'2%'}}/>
      
      <Button
        className="Button"
        sx={{ color: "#000" }}
        size="small"
        onClick={() => {
          ContactButton();
        }}
        startIcon={<img
          src={contactIcon}
          height="15"
          width="15"
          alt="Contact Icon"
          loading="lazy"
        />}
      >
        Contact
      </Button>
      <Divider sx={{backgroundColor:'#002129', margin:'2%'}}/>
      
      <HashLink to='/#services'  sx={{padding: '0%'}}>
              <Button
              variant="text"
                className="Button"
                size="small"                
                sx={{ color: "#000" }}
                startIcon={<img
                  src={serviceIcon}
                  height="15"
                  width="15"
                  alt="Service Icon"
                  loading="lazy"
                />}
              >
                Services
              </Button>
      </HashLink>
      
      </Box>
    </Box>
  );

  return (
    // <Box sx={{width:'100%', backgroundColor:'purple', display:'flex', justifyContent:'center'}}>
    <AppBar elevation={0}
      position="fixed"
      style={{ 
        // width:'100%',
        // backgroundColor: trigger ? "#002129" : "pink",
        backgroundColor: trigger ? "transparent" : "transparent",
        padding: trigger ? '5px' : '0px'
      }}
    >
      <Container maxWidth= {trigger ? 'lg' : 'xl'} elevation={trigger ? 24 : 0}
      style={{  
        width:'100%',
        boxShadow: trigger ? "5px 0px 27px -5px rgba(0, 0, 0, 0.3)" : 'none',
        backgroundColor: trigger ? "#fff" : "transparent",
        borderRadius: trigger ? '50px' : '0px',
        }}
        >
        <Toolbar disableGutters>
          <Box //COMPANY LOGO FOR LARGE SCREEN
            sx={{
              display: { xs: "none", md: "flex", cursor:'pointer' },
            }}
          >
            {trigger ? 
              < HashLink to='/#homepage'>
                <img
                  src={LogoColored}
                  height="60"
                  width={"200"}
                  alt="menu Icon"
                />
              </HashLink>
             : 
              < HashLink to='/#homepage'>
                <img
                  src={Logo}
                  height="60"
                  width={"200"}
                  alt="menu Icon"
                />
              </HashLink>
            }
           
          </Box>

          <Box //MENU ICON FOR SMALL SCREEN  
          sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            {" "}

            {trigger ? 
              // < HashLink to='/#homepage'>
              <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(true)}
              color="inherit"
            >
              <MenuIcon sx={{color:'black'}}/>
            </IconButton>
           
              // </HashLink>
             : 
              // < HashLink to='/#homepage'>
              <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(true)}
              color="inherit"
            >
              <MenuIcon/>
            </IconButton>
              // </HashLink>
            }
             <Drawer open={open} onClose={toggleDrawer(false)}>
              {DrawerList}
            </Drawer>
          </Box>

          <Box //COMPANY LOGO FOR SMALL SCREEN
            sx={{ 
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <img src={Logo1} height={"35"} width={"100"} alt="logo" onClick={ () => {navigate('/')}}/> */}
            {trigger ? 
              // < HashLink to='/#homepage'>
                <img
                  src={LogoColored}
                  height="35"
                  width={"100"}
                  alt="menu Icon"
                  onClick={ () => {navigate('/')}}
                />
              // </HashLink>
             : 
              // < HashLink to='/#homepage'>
                <img
                  src={Logo}
                  height="35"
                  width={"100"}
                  alt="menu Icon"
                  onClick={ () => {navigate('/')}}
                />
              // </HashLink>
            }
          </Box>

          <Box //MENU ITEMS FOR LARGE SCREEN
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "end",
            }}
          >
            {" "}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: {md: 'end', lg:'end'},
              }}
              >
              <HashLink to='/#homepage' style={{boxShadow:'none', textDecoration:'none'}}>
              <Typography
                variant="subtitle2"
                className="Button"
                size="small"
                fontWeight={'bold'} 
                sx={{ 
                  color: trigger ? "#002d36" : "#e6f4f1",
                  textDecoration: 'none',
                  // color: "#002d36",
                  mx: "25px", fontFamily:'open sans' }}
                  onClick={() => { navigate('/') }}
              >
                Home
              </Typography>
              </HashLink>
              <HashLink to='/#usp' style={{boxShadow:'none', textDecoration:'none'}}>
              <Typography
              variant="subtitle2"
                className="Button"
                size="small"             
                fontWeight={'bold'}   
                sx={{ 
                  color: trigger ? "#002d36" : "#e6f4f1",
                  // color: "#002d36", 
                  mx: "25px", fontFamily:'open sans' }}
                // onClick={() => {
                //   // USPButton();
                //   navigate('services')
                // }}
              >
                Who We Are
              </Typography>
              </HashLink>
              <Typography
              variant="subtitle2"
                className="Button"
                size="small"
                fontWeight={'bold'} 
                sx={{ 
                  color: trigger ? "#002d36" : "#e6f4f1",
                  // color: "#002d36", 
                  mx: "25px", fontFamily:'open sans' }}
                aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                Services
                <KeyboardArrowDownIcon className="navbarButtonIcon" style={{color: trigger ? '#002d36' : '#e6f4f1'}}/>
              </Typography>
              <Typography
              variant="subtitle2"
                className="Button"
                size="small"
                fontWeight={'bold'} 
                sx={{ 
                  color: trigger ? "#002d36" : "#e6f4f1",
                  // color: "#002d36", 
                  mx: "25px", fontFamily:'open sans' }}
                onClick={() => {
                  ContactButton();
                }}
              >
                Contact
              </Typography>
            </Box>
  
            <StyledMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            TransitionComponent={Fade}
            onClose={handleClose}
            // onMouseLeave={handleClose}
            MenuListProps={{ onMouseLeave: handleClose }}
            slotProps={{
          paper: {
            elevation: 5,
            sx: {
              padding:'1%',
              bgcolor:'#fff',
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                // bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => { navigate('/cloud-solution') }} >
        <FiberManualRecordIcon fontSize="15px" color="#002129" className="menuItemIcon"/> 
        Cloud Infrastructure & Solutions 
        </MenuItem>
        <Divider sx={{backgroundColor:'#002129'}}/>
        <MenuItem onClick={() => { navigate('/network-solution') }}>        
        <FiberManualRecordIcon fontSize="15px" color="#002129" className="menuItemIcon"/> 
        IT Network & Support
        </MenuItem>
        <Divider sx={{backgroundColor:'#002129'}}/>
        <MenuItem onClick={() => { navigate('/web-development') }}>
        <FiberManualRecordIcon fontSize="15px" color="#002129" className="menuItemIcon"/> 
        Web Development
        </MenuItem>
        <Divider sx={{backgroundColor:'#002129'}}/>
        <MenuItem onClick={() => { navigate('/uiux-designing') }}>
        <FiberManualRecordIcon fontSize="15px" color="#002129" className="menuItemIcon"/> 
        UI/UX Design
        </MenuItem>
            </StyledMenu>
          </Box>
          
          <Box //CONTACT BUTTON FOR LARGE SCREEN
          sx={{ display: { xs: 'none', lg: 'flex' } }}
          >
          <HashLink to='/#bookings'>
          <Button
                    variant="outlined" size="small" color="warning" 
                    // onClick={() => { PricingButton() }}
                    >
                    Get a quote
                  </Button>     
                  </HashLink>       
          </Box>
             
        </Toolbar>
      </Container>
    </AppBar>
    // </Box>
  );
}
export default ResponsiveAppBar;
