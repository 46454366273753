import React, { Fragment } from 'react'
import './TestimonialSlider.css'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Review1 from '../../Assets/1-removebg-preview.png';
import Review2 from '../../Assets/2-removebg-preview.png';
import Review3 from '../../Assets/3-removebg-preview.png';
import Review4 from '../../Assets/4-removebg-preview.png';

export default function TestimonialSlider() {

    const responsiveSettings = [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 300,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ];

  return (
    <Fragment>
        <Slide arrows={false}
        duration={4000}
        indicators={false}
        slidesToScroll={2}
        slidesToShow={2}
        responsive={responsiveSettings}
        >
            <div className="each-slide-effect">
                <div style={{
                        backgroundImage: `url(${Review1})`
                    }}>
                    {/* <span>Slide 1</span> */}
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{
                        backgroundImage: `url(${Review2})`
                    }}>
                    {/* <span>Slide 2</span> */}
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{
                        backgroundImage: `url(${Review3})`
                    }}>
                    {/* <span>Slide 3</span> */}
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{
                        backgroundImage: `url(${Review4})`
                    }}>
                    {/* <span>Slide 3</span> */}
                </div>
            </div>
        </Slide>
    </Fragment>
  )
}
