import React, { Fragment } from "react";
import "./Home.css";
import Navbar from "../../Components/Navbar/Navbar";
// import Carousel from "../../Components/Carousel/Carousel";
import ServiceCard from "../../Components/ServicesCards/ServiceCard";
import Footer from "../../Components/Footer/Footer";
// import Booking from "../../Components/CalendlyBooking/Booking";
import PricingPlan from "../../Components/PricingPlan/PricingPlan";
import Clients from "../../Components/Clients/Clients";
import Contact from "../../Components/Contact/Contact";
import Grid from "@mui/material/Grid2";
import { Box, Typography, Button, Chip, IconButton } from "@mui/material";
import Icon1 from "../../Assets/Customer Insight.png";
import Icon2 from "../../Assets/Cloud Development.png";
import Icon3 from "../../Assets/iMac Settings.png";
import Icon4 from "../../Assets/Management.png";
import Img1 from "../../Assets/desktopImg.png";
import Img2 from "../../Assets/mobileImg.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
// import Image from "../../Assets/Rectangle 36.png";
import BackgroundImg from "../../Assets/Image1.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Fade } from "react-awesome-reveal";
// import { styled, alpha } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ProjectCards from "../../Components/ProjectCards/ProjectCards";
import TestimonialSlider from "../../Components/TestimonialSlider/TestimonialSlider";

// const StyledChip = styled((props) => (
//   <Chip
//   variant="filled"
//   {...props}
//   />
// ))(({ theme }) => ({
//     '& .MuiChip-root': {
//       fontSize:'small',
//       borderRadius:'5px',
//       fontWeight:'bold',

//       '& .MuiChip-label': {
//         color:'pink'
//       },
//     },
//     ...theme.applyStyles('dark', {
//       color: theme.palette.grey[300],
//     }),
// }));

const reasonsList = [
  {
    id: 1,
    icon: Icon1,
    title: "Our Approach",
    description:
      "At ClovVista, we take a holistic approach to consulting. We work closely with our clients to understand their goals and challenges, and develop solutions that address their specific needs. Our approach is collaborative, transparent, and results-driven.",
  },
  {
    id: 1,
    icon: Icon2,
    title: "Innovative Technology",
    description:
      "We stay up-to-date with the latest technology trends and offer innovative solutions that help you stay ahead of the competition.",
  },
  {
    id: 1,
    icon: Icon3,
    title: "Our Expertise",
    description:
      "Our team at ClovVista is made up of highly skilled professionals with diverse backgrounds. We work together to provide our clients with tailored solutions that meet their unique needs. Meet our team and learn more about our individual areas of expertise.",
  },
  {
    id: 1,
    icon: Icon4,
    title: "Industry Expertise",
    description:
      "At ClovVista, we have years of experience in the consulting industry. We specialize in providing top-notch services in areas such as marketing, strategy, and operations. Our expertise can help take your business to the next level.",
  },
];

export default function Home() {
  const navigate = useNavigate()

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  // const trigger = useScrollTrigger({ disableHysteresis: true });

  // const ContactButton = () => {
  //   const element = document.getElementById("contact");
  //   console.log(element);
  //   if (element) {
  //     // 👇 Will scroll smoothly to the top of the next section
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  const BookingButton = () => {
    const element = document.getElementById("bookings");
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const LearnButton = () => {
    const element = document.getElementById("services");
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const clientList = [
  //   {
  //     id: '1',
  //     link: Client1
  //   },
  //   {
  //     id: '2',
  //     link: Client2
  //   },
  //   {
  //     id: '3',
  //     link: Client3
  //   },
  //   {
  //     id: '4',
  //     link: Client4
  //   }
  // ]
  return (
    <Fragment>
      <Box sx={{width:'100%', backgroundColor:'red', display:'flex', justifyContent:'center', alignItems:'center' }}>
      <Navbar />
      </Box>

      <Grid container>
        <Grid //HERO SECTION
          size={{ xs: 12, md: 12 }}
          id="homepage"
          sx={{ backgroundColor: "hsla(0, 0%, 0%, 0.521)" }}
        >
          <Box className="homeHeroSection">
            <Box className="homeHeroOverlay">
              <Box className="homeImgContainer">
                <img
                // loading="lazy"
                  src={BackgroundImg}
                  class="img-fluid"
                  alt="backgroundImage"
                  loading="eager"
                  width={'100%'}
                  // style={{position:'relative'}}
                />
              </Box>
              <Box className="homeHeroContent">
                <Box className="homeHeroInnerContainer">
                  <Fade cascade triggerOnce="true">
                    
                    <Typography
                      variant="h3"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      mt={1}
                      color="white"
                      align="center"
                      sx={{ fontSize: { lg: 66, md: 50, xs: 15 } }}
                    >
                      Revolutionizing Your Business Operations
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontFamily={"open sans"}
                      mt={2}
                      // mx={1}
                      color="white"
                      align="center"
                      sx={{ fontSize: { sm: 15, xs: 10 } }}
                    >
                      Innovative Strategies To Transform Your Business
                    </Typography>
                    <Box className="homeHeroButtonContainer">
                      {/* <Fade cascade> */}
                      <Button
                        variant="outlined"
                        // color="inherit"
                        endIcon={
                          <ArrowOutwardIcon
                            className="homeHeroButtonIcon2"
                            sx={{ display: { xs: "none", md: "Flex" } }}
                          />
                        }
                        sx={{
                          marginLeft: "0px",
                          fontSize: { xs: "0.5rem", md: "0.8rem" } , marginTop:'1rem',  width:{xl:'20rem', lg:'15rem', md:'12rem', xs:'10rem' },
                          color: "white",
                          border: "1px solid white",
                        }}
                        onClick={() => {
                          BookingButton();
                        }}
                      >
                        Book A Consultation
                      </Button>
                      <Button
                        variant="contained"
                        color="warning"
                        endIcon={
                          <ArrowForwardIcon
                            className="homeHeroButtonIcon"
                            sx={{ display: { xs: "none", md: "flex" } }}
                          />
                        }
                        onClick={() => {
                          LearnButton();
                        }}
                        sx={{ fontSize: { xs: "0.5rem", md: "0.8rem" }, width:{xl:'20rem', lg:'15rem', md:'12rem', xs:'10rem' },
                        marginLeft:'1rem', marginTop:'1rem', padding:'0rem 2.5rem 0rem 2.5rem' }}
                      >
                        Learn More
                      </Button>
                     
                      {/* </Fade> */}
                    </Box>
                  </Fade>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid //ABOUT US SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          id="about"
          sx={{ width: "100%", backgroundColor: "#fafaf7" }}
        >
          <Box className="homeUSPOuterContainer">
            <Box className="InnerContainer">
              <Fade cascade triggerOnce="true">
                <Chip
                  label="ABOUT US"
                  className="homeChip"
                  sx={{ borderRadius: "5px", padding: "0px" }}
                />
                <Typography
                  variant="h4"
                  fontWeight={"bold"}
                  fontFamily={"open sans"}
                  mt={1}
                >
                  Driving Innovation And Delivering Tailored Solutions
                </Typography>
                <Typography variant="body2" fontFamily={"open sans"} mt={2}>
                  ClovVista is more than simply a consulting firm; we are a
                  strategic partner committed to achieving business success with
                  innovative, bespoke solutions. Our comprehensive process
                  assures a smooth and efficient experience that relates to your
                  long-term objectives.
                </Typography>
                {/* <Typography variant="body2" fontFamily={"open sans"} my={2}>
                At ClovVista, we strive to surpass expectations by encouraging
                cooperation, openness, and a client-centric approach. Our
                comprehensive process assures a smooth and efficient experience
                that relates to your long-term objectives.
              </Typography> */}
              </Fade>
              <Box className="homeAboutClients">
                <Clients />
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid //OUR SERVICES SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          id="services"
          sx={{ width: "100%", backgroundColor: "#fafaf7" }}
        >
          <Box className="OuterContainer">
            <Box className="InnerContainer">
              <Grid container spacing={3}>
                <Grid size={{ xs: 12, md: 12, lg: 7 }}>
                  <Chip
                    label="OUR CONSULTING SERVICES"
                    className="homeChip"
                    sx={{ borderRadius: "5px" }}
                  />
                  <Typography
                    variant="h4"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    mt={1}
                    // mb={4}
                    sx={{ color: "black" }}
                    // align="center"
                  >
                    Providing Strategic Insights and Guidelines to Empower
                    Businesses
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 12, lg: 5 }}
                  sx={{
                    // backgroundColor:'pink',
                    display: "flex",
                    // alignItems: "end",
                    // paddingBottom: "8px",
                  }}
                >
                  <Typography
                    variant="body2"
                    fontFamily={"open sans"}
                    align="left"
                  >
                    Our consulting services focus on delivering tailored
                    strategies to help businesses navigate challenges and seize
                    opportunities. By offering expert insights and actionable
                    guidelines, we empower organizations to achieve sustainable
                    growth and enhance operational efficiency
                  </Typography>
                </Grid>
              </Grid>

              <ServiceCard />
            </Box>
          </Box>
        </Grid>

        <Grid //PICTURE SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          id="services"
          sx={{ width: "100%", backgroundColor: "#fafaf7" }}
        >
          <Box className="pictureContainer">
            <Box className="InnerContainer">
              <Grid container spacing={3}>
                <Grid size={{ xs: 12, md: 12, lg: 12 }}>
<Box className='homeQuote'>
<Typography
                    variant="h4"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    mt={1}
                    // mb={4}
                    sx={{ color: "white", fontSize: { lg: 40, md: 25, xs: 15 } }}
                    align="center"
                    display='flex'
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    "Providing Strategic Insights and Guidelines to Empower Businesses"
                  </Typography> 
</Box>
                  {/* <Chip
                    label="OUR CONSULTING SERVICES"
                    className="homeChip"
                    sx={{ borderRadius: "5px" }}
                  /> */}
                  
                </Grid>
                {/* <Grid size={{ xs: 12, md: 12, lg: 5 }}
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    paddingBottom: "8px",
                  }}
                >
                  <Typography
                    variant="body2"
                    fontFamily={"open sans"}
                    align="left"
                  >
                    Our consulting services focus on delivering tailored
                    strategies to help businesses navigate challenges and seize
                    opportunities. By offering expert insights and actionable
                    guidelines, we empower organizations to achieve sustainable
                    growth and enhance operational efficiency
                  </Typography>
                </Grid> */}
              </Grid>

              {/* <ServiceCard /> */}
            </Box>
          </Box>
        </Grid>

        <Grid //UNIQUE SELLING POINTS SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          id="usp"
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Box className="OuterContainer" sx={{ backgroundColor: "#E6F4F1" }}>
            <Box className="InnerContainer">
              <Grid container spacing={3}>
                <Grid size={{ xs: 12, md: 12, lg: 7 }}>
                  <Chip
                    label="NEW OPPORTUNITY"
                    className="homeChip"
                    sx={{ borderRadius: "5px", padding: "0px" }}
                  />
                  <Typography
                    variant="h4"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    mt={1}
                    sx={{ color: "black" }}
                  >
                    We Build Solutions That Are Bold And <br /> Contemporary
                  </Typography>
                </Grid>
                <Grid
                  size={{ xs: 12, md: 12, lg: 5 }}
                  sx={{
                    display: "flex",
                    // alignItems: "end",
                    // paddingBottom: "8px",
                  }}
                >
                  <Typography
                    variant="body2"
                    fontFamily={"open sans"}
                    align="left"
                  >
                    At our core, we believe in building bold, contemporary
                    solutions that drive results. Our innovative approach,
                    combined with a deep understanding of industry trends,
                    allows us to deliver strategies that solve today’s
                    challenges but also set your business up for future success.
                    Choose us for a partner who’s committed to your growth and
                    excellence.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} mt={5}>
                <Grid size={{ xs: 12, md: 12, lg: 7 }}>
                  {reasonsList.map((index) => {
                    return (
                      <Box
                        sx={{
                          backgroundColor: "none",
                          padding: "10px 2px",
                          mb: "10px",
                          // borderBottom: "1px solid #002129",
                        }}
                      >
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Box>
                            <IconButton
                              sx={{
                                backgroundColor: "hsl(201, 100%, 14%,0.1)",
                              }}
                            >
                              <img
                                src={index.icon}
                                // class="img-fluid"
                                height="40"
                                width="40"
                                alt="Our Approach"
                                loading="lazy"
                              />
                            </IconButton>
                          </Box>
                          <Typography variant="h6" mx={3} fontWeight={"bold"}>
                            {index.title}
                            <Typography variant="subtitle2">
                              {index.description}
                            </Typography>
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Grid>
                <Grid
                  size={{ xs: 12, md: 12, lg: 5 }}
                  sx={{ backgroundColor: "none" }}
                >
                  <Box sx={{ display: {xs:'none', sm:'none', md:'none', lg:'flex'}, justifyContent: "center" }}>
                    <img
                      src={Img1}
                      class="img-fluid"
                      // height="400"
                      // width="400"
                      alt="Wny Choose Us"
                      loading="lazy"
                    />
                  </Box>

                  <Box sx={{ display: {xs:'flex', sm:'flex', md:'flex', lg:'none'}, justifyContent: "center" }}>
                    <img
                      src={Img2}
                      class="img-fluid"
                      // height="400"
                      // width="400"
                      alt="Wny Choose Us"
                      loading="lazy"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid //PROJECTS SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          sx={{ width: "100%", backgroundColor: "#fafaf7" }}
        >
          <Box className="OuterContainer">
            <Box className="InnerContainer">
              <Fade triggerOnce="true">
                <Grid container spacing={3}>
                  <Grid size={{ xs: 12, md: 12, lg: 7 }}>
                    <Chip
                      label="LATEST PORTFOLIO"
                      className="homeChip"
                      sx={{ borderRadius: "5px", padding: "0px" }}
                    />
                    <Typography
                      variant="h4"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      mt={1}
                      // mb={4}
                      sx={{ color: "black" }}
                      // align="center"
                    >
                      Where Innovation Meets IT Excellence
                    </Typography>
                  </Grid>
                  <Grid
                    size={{ xs: 12, md: 12, lg: 5 }}
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      paddingBottom: "8px",
                      flexDirection:'column'
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontFamily={"open sans"}
                      align="left"
                      color="black"
                    >
                      Discover our latest portfolio, showcasing cutting-edge IT
                      solutions that blend innovation with technical expertise.
                      Each project reflects our commitment to delivering
                      excellence and driving success for businesses across
                      diverse industries.
                    </Typography>
                    {/* <Typography className="serviceLearnMoreText" variant="caption" align="left" color="black" 
                    onClick={() =>{navigate('/case-studies')}}>
                      Learn More 
                      <ArrowOutwardIcon fontSize="xs" sx={{marginLeft:'5px'}}/>
                    </Typography> */}
                  </Grid>
                </Grid>
              </Fade>
            </Box>
            <Box sx={{ width: "100%", padding: "0% 5px" }}>
              <ProjectCards />
            </Box>
          </Box>
        </Grid>

        <Grid //COLORED BAND SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          id="bookings"
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Box className="OuterContainer" sx={{ backgroundColor: "#E6F4F1" }}>
            <Box className="InnerContainer">
              <Fade triggerOnce="true">
                <Grid container spacing={3} mb={3}>
                  <Grid size={{ xs: 12, md: 12, lg: 7 }}>
                    <Chip
                      label="BOOK AN ONLINE APPOINTMENT"
                      className="homeChip"
                      sx={{ borderRadius: "5px", padding: "0px" }}
                    />
                    <Typography
                      variant="h4"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      mt={1}
                      // mb={4}
                      sx={{ color: "black" }}
                      // align="center"
                    >
                      Choose the Perfect Plan for Your Needs
                    </Typography>
                    {/* <Typography
                      variant="body2"
                      // align="center"
                      // fontWeight={"bold"}
                      fontFamily={"open sans"}
                      my={1}
                      mb={4}
                      color="black"
                    >
                      We’re here to help to grow your business.
                    </Typography> */}
                  </Grid>
                  <Grid
                    size={{ xs: 12, md: 12, lg: 5 }}
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      paddingBottom: "8px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontFamily={"open sans"}
                      align="left"
                      color="black"
                    >
                      Our services are designed to meet the unique needs of your
                      business, providing flexible plans that adapt to your
                      goals. Whether you're looking to streamline operations,
                      boost productivity, or expand your market presence, we
                      offer the expert support and guidance necessary for
                      growth.
                    </Typography>
                  </Grid>
                </Grid>

                <PricingPlan />
              </Fade>
            </Box>
          </Box>
        </Grid>

        <Grid //TESTIMONIALS SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          id="bookings"
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Box className="OuterContainer" sx={{ backgroundColor: "#fafaf7" }}>
            <Box className="InnerContainer">
              <Fade triggerOnce="true">
                <Grid container spacing={3} mb={0}>
                  <Grid size={{ xs: 12, md: 12, lg: 4 }}>
                    <Chip
                      label="TESTIMONIALS"
                      className="homeChip"
                      sx={{ borderRadius: "5px", padding: "0px" }}
                    />
                    <Typography
                      variant="h4"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      mt={1}
                      // mb={4}
                      sx={{ color: "black" }}
                      // align="center"
                    >
                      Trusted Voices, Proven Results
                    </Typography>
                    <Typography
                      variant="body2"
                      // align="center"
                      // fontWeight={"bold"}
                      fontFamily={"open sans"}
                      my={1}
                      mb={4}
                      color="black"
                    >
                      Hear directly from our valued clients about 
                      their experiences with our consulting services. 
                      </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 12, lg: 8 }}
                    sx={{
                      // display: "flex",
                      // alignItems: "end",
                      justifyContent: 'center',
                      // paddingBottom: "8px",
                      // backgroundColor: 'pink'
                    }}
                  >
                    <TestimonialSlider/>
                  </Grid>
                </Grid>

                {/* <PricingPlan /> */}
              </Fade>
            </Box>
          </Box>
        </Grid>

        <Grid //CONTACT SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          id="contact"
          sx={{ width: "100%", backgroundColor: "#fafaf7" }}
        >
          <Box className="OuterContainer">
            <Box className="InnerContainer">
              <Contact/>
            </Box>
            
          </Box>
        </Grid>

        <Grid //FOOTER SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Footer />
        </Grid>
      </Grid>
      {/* </Fragment> */}
    </Fragment>
  );
}
